import React, { useState, useEffect } from "react";
import "./App.css";
import LandingPage from "./components/LandingPage.js";
import LoadingModal from "./components/LoadingModal.js";
import { setDataState } from "./store/reducers/notificationSlice.js";
import { DATA_STATE } from "./helpers/app.constants.js";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!window.location.hash) {
      dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
      window.location = window.location + "#loaded";
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      setIsLoaded(true); 
    }
  }, [dispatch]);

  return (
    <div>
      {isLoaded && <LandingPage />} 
      <LoadingModal />
    </div>
  );
}

export default App;
